import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { NavbarBrand, Image } from 'react-bootstrap';

const Solutions = () => {
  return (
    <div class="solution-section">
      <div class="about-section ">
        <h1>Our Solutions</h1>
        <p>
          We provide a range of IT related services at reasonable cost and with
          highest quality possible.
        </p>
        <div>
          <p class="text-xl-center font-weight-bold text-dark">
            Explore our trusted solutions below to get started with NextGenIT.
          </p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image src={'./webdesign.png'} alt="Hosting" width={100} />
            </div>
            <div class="service-content">
              <h3>Web Design &amp; Development</h3>
              <p>
                NextGenIT specializes in website design and development
                services. Our web experiences are high-performing,
                feature-packed and digitally ..
              </p>
              <LinkContainer to="/webdesign">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image src={'./domain.png'} alt="Hosting" width={100} />
            </div>
            <div class="service-content">
              <h3>Domain Registration</h3>
              <p>
                Finding the proper domain name for your website is simple with
                NextGenIT. We also provide a full range of domain services,
                including domain registration..
              </p>
              <LinkContainer to="/domainregistration">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image
                src={'./softwaredevelopment.png'}
                alt="Hosting"
                width={100}
                className="text-dark"
              />
            </div>
            <div class="service-content">
              <h3>Software Development</h3>
              <p>
                NextGenIT offer services to help businesses develop and create
                software based on the requirements of their client...
              </p>
              <LinkContainer to="/softwaredevelopment">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image src={'./itconsultant.png'} alt="Hosting" width={100} />
            </div>
            <div class="service-content">
              <h3>IT Consultancy</h3>
              <p>
                NextGenIT offer IT consulting services that will help you
                improve your software architecture, create a tech-driven digital
                strategy..
              </p>
              <LinkContainer to="/itconsultant">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
              '
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image src={'./systemadmin.png'} alt="Hosting" width={100} />
            </div>
            <div class="service-content">
              <h3>System Administration</h3>
              <p>
                NextGenIT offer most reliable system administration services
                including OS maintenance and troubleshooting services and server
                monitoring ..
              </p>
              <LinkContainer to="/systemadmin">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="service-wrap hvr-buzz-out">
            <div class="service-icon">
              <Image src={'./ecommerce.png'} alt="Hosting" width={100} />
            </div>
            <div class="service-content">
              <h3>eCommerce Solutions</h3>
              <p>
                Ecommerce services cover all technology-related needs of an
                ecommerce business. An ecommerce services provider,..
              </p>
              <LinkContainer to="/ecommerce">
                <NavbarBrand className="footer-copyright-col">
                  <button
                    title="Web Design &amp;amp; Development"
                    class="main-btn1"
                  >
                    Read More
                  </button>
                </NavbarBrand>
              </LinkContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Solutions;
