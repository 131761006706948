import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const DomainScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/domain.png" width={400} />
            </Col>
            <Col>
              <h1 class="text-warning">Domain Registration</h1>
              <p>
                Finding the proper domain name for your website is simple with
                NextGenIT. We also provide a full range of domain services,
                including domain registration, domain transfers, domain
                expiration protection, and domain privacy, all of which are both
                affordable and hassle-free.
              </p>
              <p>
                Domain registration is the backbone of any company or brand for
                its digital presence to the target audience. For official domain
                registration services in Nepal, NextGenIT provides the best and
                affordable domain registration price in Nepal in collaboration
                with well-known domain host provider. Some of the packages are
                built with a free domain while purchasing the hosting from us.
                We offers one of the cheapest domain registration company in
                Nepal. We can provide perfect domain names like .com, .net,
                .org, .in, and np domain registration which is essential for the
                proper functioning of the website at an affordable cost. Domain
                names is important because - It's your “first impression towards
                your visitors towards your site, A bad domain name will make
                your website downfall. Domains name also affects SEO. if you
                have same domain and misspell the words in domains. A domain
                name is a fundamental part of having a website, but it's only
                part of the equation. In order to launch a website, you'll also
                need content and a hosting service to store your files so they
                can be accessed on the internet.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default DomainScreen;
