import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from './components/Header';
import Footer from './components/Footer';
import HomeScreen from './screens/HomeScreen';
import Solutions from './screens/Solutions';
import AboutUsScreen from './screens/AboutUsScreen';
import Support from './screens/Support';
import RequestDemo from './screens/RequestDemo';
import ContactUsScreen from './screens/ContactUsScreen';
import CareerScreen from './screens/CareerScreen';
import PressScreenShowList from './screens/PressScreenShowList';
import PartnersScreen from './screens/PartnersScreen';

import DomainScreen from './screens/solutions/DomainScreen';
import EcommerceScreen from './screens/solutions/EcommerceScreen';
import ItConsultantScreen from './screens/solutions/ItConsultantScreen';
import SoftwareScreen from './screens/solutions/SoftwareScreen';
import WebDesignScreen from './screens/solutions/WebDesignScreen';
import SystemAdminScreen from './screens/solutions/SystemAdminScreen';

const App = () => {
  return (
    <Router>
      <Header />
      <main className="py-3">
        <Container>
          <Route path="/search/:keyword" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            component={HomeScreen}
            exact
          />

          <Route path="/solutions" component={Solutions} />
          <Route path="/aboutus" component={AboutUsScreen} />
          <Route path="/contactus" component={ContactUsScreen} />
          <Route path="/career" component={CareerScreen} />
          <Route path="/support" component={Support} exact />
          <Route path="/requestdemo" component={RequestDemo} exact />
          <Route path="/partner" component={PartnersScreen} />

          <Route path="/domainregistration" component={DomainScreen} />
          <Route path="/ecommerce" component={EcommerceScreen} />
          <Route path="/itconsultant" component={ItConsultantScreen} />
          <Route path="/softwaredevelopment" component={SoftwareScreen} />
          <Route path="/webdesign" component={WebDesignScreen} />
          <Route path="/systemadmin" component={SystemAdminScreen} />

          <Route path="/presss" component={PressScreenShowList} />
          <Route path="/" component={HomeScreen} exact />
        </Container>
      </main>
      <Footer />
    </Router>
  );
};

export default App;
