import React from "react";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinkContainer } from "react-router-bootstrap";
import { NavbarBrand } from "react-bootstrap";
import ScrollUpButton from "react-scroll-up-button";
import "../index.css";

const Footer = () => {
  return (
    <footer id="footer" class="footer navbar-inverse navbar-fixed-bottom">
      <div class="main-footer widgets-dark typo-light">
        <div class="container">
          <div class="row">
            <div class="col">
              <LinkContainer to="/aboutus">
                <NavbarBrand className="footer-copyright-col">
                  About Us
                </NavbarBrand>
              </LinkContainer>
            </div>
            <div class="col">
              <LinkContainer to="/presss">
                <NavbarBrand className="footer-copyright-col">
                  Press
                </NavbarBrand>
              </LinkContainer>
            </div>
            <div class="col">
              <LinkContainer to="/partner">
                <NavbarBrand className="footer-copyright-col">
                  Our Partners
                </NavbarBrand>
              </LinkContainer>
            </div>
            <div class="col">
              <LinkContainer to="/career">
                <NavbarBrand className="footer-copyright-col">
                  Careers
                </NavbarBrand>
              </LinkContainer>
            </div>
            <div class="col">
              <LinkContainer to="/contactus">
                <NavbarBrand className="footer-copyright-col">
                  Contact Us
                </NavbarBrand>
              </LinkContainer>
            </div>
            <div>
              <a
                href="https://www.facebook.com/nextgenitsoftwaretechnologies"
                target="_blank"
                className="facebook social"
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" color="#4968ad" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ScrollUpButton />
      </div>
      <div class="footer-copyright">
        <p class="text-center">
          Copyright © 2022 NextGenIT Software Technologies. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
