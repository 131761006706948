import React from 'react';

const PressScreenShowList = ({ match }) => {
  function formatDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  return (
    <>
      <h1 class="header__title common-section common-h2">
        News and information
      </h1>
      <h3 class="common-h3">Latest news</h3>
      <p>Sorry, nothing to show at the moment....</p>
    </>
  );
};

export default PressScreenShowList;
