import React from 'react';
import '../index.css';

const ContactUsScreen = () => {
  return (
    <div class="common-section">
      <header>
        <h1 class="text-warning">Contact NextGenIT</h1>
      </header>
      <p style={{ color: 'red' }}>
        Note: please use email instead of the phone number to reach us as we are
        currently only available online.
      </p>
      <div class="mt-4">
        <div class="footer-contact-wrap">
          <div class="footer-contact-icon">
            <i class="far fa-envelope"></i>
          </div>
          <div>
            <a href="mailto:nextgenit022@gmail.com">
              <span>nextgenit022@gmail.com</span>
            </a>
          </div>
        </div>
        <div class="footer-contact-wrap">
          <div class="footer-contact-icon">
            <i class="fa fa-phone"></i>
          </div>
          <div>
            <a href="tel:9845114780">
              <span>9845114780</span>
            </a>
            <span>, </span>
            <a href="tel:9845018079">
              <span>9845018079</span>
            </a>
          </div>
        </div>
        <h2 class="mt-5 mb-4 text-warning">Our offices</h2>
        <div class="row">
          <div class="col-md-3 col-lg-6">
            <div>
              Bhandara, Chitwan (Headquarters)
              <div>
                <div class="footer-contact-wrap">
                  <div class="footer-contact-icon">
                    <a
                      href="https://goo.gl/maps/xyvxq4jLdxssbDKH7"
                      title="Bhandara, Chitwan, Nepal"
                      target="_blank"
                    >
                      <i class="fas fa-map-marker-alt"></i>
                    </a>
                  </div>
                  <a
                    href="https://goo.gl/maps/xyvxq4jLdxssbDKH7"
                    title="Bhandara, Chitwan, Nepal"
                    target="_blank"
                  >
                    Rapti-4, Laxminarayan Mandir, Bhandara, Chitwan, Nepal
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsScreen;
