import React from "react";
import { Card } from "react-bootstrap";
import "../index.css";

const AboutUsScreen = () => {
  return (
    <>
      <div class="about-section ">
        <h1>About Us</h1>
        <p>
          NextGenIT is a software development company established in 2022. We
          provide comprehensive services including development of web-oriented
          application, mobile applications, business software solutions, as well
          as support, and maintenance of software applications.
        </p>
        <p>
          We aimed to offer custom-made coding solutions on HTML, CSS,
          JavaScript, React, frontend frameworks, etc every day.
        </p>
      </div>

      <br />
      <br />
      <h1 class="text-center">Our Team</h1>
      <div class="row">
        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/ceo.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Hari Narayan Shrestha</h2>
              <p class="about-title">CEO &amp; Founder</p>
              <p>harins2011@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/tester.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Laxmi Shrestha</h2>
              <p class="about-title">Junior Software Tester</p>
              <p>nextgenit022@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/ishwore.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Ishwore Chaudhary</h2>
              <p class="about-title">Intern Full-Stack Developer</p>
              <p>chaudharyishwore@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/suman.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Suman Shrestha</h2>
              <p class="about-title">Intern Frontend Developer</p>
              <p>stha7420@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/saujan.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Saujan Khanal</h2>
              <p class="about-title">Intern Frontend Developer</p>
              <p>saujankhanal003@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/purushottam.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Purushottam Shrestha</h2>
              <p class="about-title">Intern Software Developer</p>
              <p>purushottamshrestha0123@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/aryan.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Aaryan Dhakal</h2>
              <p class="about-title">Intern Software Developer</p>
              <p>aaryan.dhakal12@gmail.com</p>
            </div>
          </div>
        </div>

        <div class="about-column">
          <div class="about-card">
            <Card.Img src="/images/anjana.jpg" variant="top" />
            <div class="about-container">
              <h2 class="about-name">Anjana Shrestha</h2>
              <p class="about-title">Junior Test Automation Engineer</p>
              <p>nextgenit022@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUsScreen;
