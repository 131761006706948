import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};

const store = configureStore(
  { reducer: combineReducers({}) },
  initialState,
  composeWithDevTools()
);

export default store;
