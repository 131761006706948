import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const SoftwareScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/softwaredevelopment.png" width={400} />
            </Col>
            <Col>
              <p>
                NextGenIT offer services to help businesses develop and create
                software based on the requirements of their client. In some
                cases, software developer providers can also be hired to enhance
                a business' existing product. These providers will estimate the
                time and cost of a project, then will design, develop, test, and
                deliver the product to the client. These providers help
                businesses that do not have a full software development team to
                create new products and maintain existing products. Businesses
                that have software development teams can also benefit from
                software developer providers in times when the team needs
                assistance on a project or simply for consultation purposes. In
                addition to hiring a software developer, many businesses may
                choose to hire testing and QA providers to test a newly
                developed product if the software developer providers do not
                offer it. In some cases, it's worth it for the business to hire
                software developer providers that specialize in a specific area
                depending on the project. For example, a business that's looking
                to create an iOS application may want to hire iOS developers
                since they specialize in iOS applications. To qualify for
                inclusion in the Software Developer Services Providers category,
                a provider must: Design, develop, QA and deliver software
                Provide developers with a specific set or variety of
                specialities Respond to client feedback and provide design
                adjustments when requested
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SoftwareScreen;
