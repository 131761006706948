import React from 'react';

const PartnersScreen = () => {
  const customers = [
    {
      id: '01',
      name: 'Maskey SHopping Complex',
    },
    {
      id: '02',
      name: 'Laxminarayan Venkatesh Suppliers',
    },
  ];
  const joinList = [customers, customers];
  return (
    <div class="common-section text-left">
      <h1 class="text-warning common-section">
        Our Happy Customers &amp; Partners
      </h1>
      <ul>
        {customers.map((data) => (
          <li key={data.id}>
            <p>{data.name}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PartnersScreen;
