import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { NavbarBrand } from "react-bootstrap";
import { Form, Row, Col, Button } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const SERVICE_ID = "nextgenit_gmail";
const TEMPLATE_ID = "nextgenit_solninquiries";
const USER_ID = "_M4VJn7UQ-tuUDb7P";

const RequestDemo = () => {
  const submitHandler = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      })
      .catch((err) => {
        console.log("FAILED...", err);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
        });
      });
    e.target.reset();
  };

  return (
    <div>
      <div class="about-section">
        <h2>Solution inquiries</h2>
        <p>
          If you are a new customer, organization, institutus or another
          organization that supports digital services and you want to learn more
          about our services and products, please complete the form below and
          someone from our Business Development team will be in touch with you
          shortly.
        </p>
      </div>
      <br />
      <div>
        <LinkContainer to="/support">
          <NavbarBrand className="text-warning">SUPPORT REQUESTS:</NavbarBrand>
        </LinkContainer>
        If you are seeking technical support for a product or project, or a
        login reset, please use our support page to reach the appropriate
        helpdesk.&nbsp;
        <u>
          Support requests received via this form will not reach the helpdesk.
        </u>
      </div>
      <br />

      <FormContainer>
        <h1 class="text-warning">Solution inquiries</h1>
        <Form onSubmit={submitHandler}>
          <Row>
            <Col>
              <Form.Group controlId="firstname">
                <Form.Control
                  name="firstname"
                  type="text"
                  placeholder="First Name*"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="lastname">
                <Form.Control
                  name="lastname"
                  type="text"
                  placeholder="Last Name*"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="company">
                <Form.Control
                  name="company"
                  type="text"
                  placeholder="Company*"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="email">
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="Email*"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="phone">
                <Form.Control
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="address">
                <Form.Control
                  name="address"
                  type="text"
                  placeholder="Address"
                  required
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <h4 class="text-warning">Product Interest</h4>
          <Row>
            <Col>
              <Form.Group controlId="domain">
                <Form.Check
                  name="domain"
                  type="switch"
                  id="domain"
                  label="Domain Registration"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="webpage">
                <Form.Check
                  name="webpage"
                  type="switch"
                  id="webpage"
                  label="Website Design and Hosting"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="reservation">
                <Form.Check
                  name="reservation"
                  type="switch"
                  id="reserve"
                  label="Reservation System"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="booking">
                <Form.Check
                  name="booking"
                  type="switch"
                  id="hotel"
                  label="Hotel Booking System"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="application">
                <Form.Check
                  name="application"
                  type="switch"
                  id="desktop"
                  label="Desktop Application"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="billing">
                <Form.Check
                  name="billing"
                  type="switch"
                  id="billing"
                  label="Billing System"
                />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Form.Group controlId="comment">
            <Form.Control
              name="comment"
              as="textarea"
              row="6"
              placeholder="Comments*"
              required
            ></Form.Control>
          </Form.Group>
          <br />
          <Form.Check
            type="switch"
            id="agree"
            label="I understand and agree to the privacy policy*"
            required
          />

          <Form.Check
            type="switch"
            id="agree1"
            label="I agree to be contacted via email and automated technologies which may include calling, text and recorded messages*"
            required
          />
          <br />
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </Form>
      </FormContainer>
    </div>
  );
};

export default RequestDemo;
