import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const WebDesignScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/systemadmin.png" width={400} />
            </Col>
            <Col>
              <p>
                NextGenIT specializes in website design and development
                services. Our web experiences are high-performing,
                feature-packed and digitally transformative, designed to be
                user-friendly, fully functional, very secure and able to scale
                as your enterprise grows.
              </p>
              <p>
                Our website developers provide expert web application
                development and web design services to our clients. NextGenIT
                offers a variety of website design and development services,
                from creating mobile web development solutions and responsive
                website designs, to building custom e-commerce and intranet
                experiences using the latest and proven web technologies. With
                up to 85% of consumers visiting company's or service provider's
                website before making a purchase, more and more consumers make
                decisions based on their online experience: the appearance,
                usability and accessibility of your website is more important
                than ever, especially in an increasingly competitive market.
              </p>
              Client-focused, customer-centric, creating website solutions that
              deliver tangible business results, NextGenIT's web developers
              helps brands the ever-changing digital landscape.
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WebDesignScreen;
