import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, Container, NavbarBrand, NavItem } from 'react-bootstrap';

const Header = () => {
  return (
    <div>
      <div class="fixed-top-1"></div>
      <header>
        <Navbar
          className="fixed-top"
          variant="dark"
          expand="lg"
          collapseOnSelect
        >
          <Container>
            <LinkContainer to="/">
              <img src={'./images/nextgenit.png'} width={200} />
            </LinkContainer>
            <Navbar.Toggle aria-controls="basic-navbar-nav" className="hover" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <LinkContainer to="/solutions">
                  <Navbar.Brand className="text-blue">Solutions</Navbar.Brand>
                </LinkContainer>
                <LinkContainer to="/aboutus">
                  <NavbarBrand className="text-blue">About Us</NavbarBrand>
                </LinkContainer>
                <LinkContainer to="/support">
                  <NavbarBrand className="text-blue">Support</NavbarBrand>
                </LinkContainer>
                <LinkContainer to="/requestdemo">
                  <NavbarBrand className="text-blue">
                    Request a Demo
                  </NavbarBrand>
                </LinkContainer>

                <LinkContainer to="">
                  <NavItem>Sign In</NavItem>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </div>
  );
};

export default Header;
