import React, { useState } from 'react';
import axios from 'axios';

const Support = () => {
  const [formData, setFormData, setText] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const { name, email, phone, subject, message } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();

    // add route for the backend
    const form = await axios.post('/api/form', {
      name,
      email,
      phone,
      subject,
      message,
    });
  };
  return (
    <div>
      <div class="about-section ">
        <img src={'./support.png'} width={340} />
      </div>
      <br />
      <div>
        <h1>REMOTE SUPPORT</h1>
        <p>
          Do you have a Support Agreement and need help with your application?
          Call our technical support at 9845114780.
        </p>

        <h3>Technical support opening hours</h3>
        <p>Weekdays from 09:00 to 20:00</p>
        <h2>Do you have any questions?</h2>
        <p style={{ color: 'red' }}>
          Note: please use email instead of the phone number to reach us as we
          are currently only available online.
        </p>
        <div class="row mt-4">
          <div class="col">
            <img src="/images/callus.png" width="200" />
            <div class="about-container">
              <br />
              <h5>Call us</h5>
              <p>9809129697</p>
              <p>Monday - Friday 9-20</p>
              <p>Saturday 10-16</p>
            </div>
          </div>

          <div class="col">
            <img src="/images/customerservice.png" width="200" />
            <div class="about-container">
              <h5>Customer service</h5>
              <p>9844208987</p>
              <p>Monday - Friday 9-20</p>
              <p>Saturday 10-16</p>
            </div>
          </div>
          <div class="col">
            <img src="/images/email.png" width="300" />
            <div class="about-container">
              <h5>Send an Email</h5>
              <p>nextgenit022@gmail.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
