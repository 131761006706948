import React from 'react';
import { Carousel, Image, Container, Row, Col } from 'react-bootstrap';

const HomeScreen = ({ match }) => {
  return (
    <>
      <div class="common-section">
        <div>
          <Carousel pause="hover" className="bg-blue">
            <Carousel.Item>
              <Container>
                <Row>
                  <Col md={{ span: 1, offset: 1 }}>
                    <Image
                      src={'./domain.png'}
                      alt="Hosting"
                      className="bg-white"
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Carousel.Caption>
                      <h3 class="text-warning">Domain Registration</h3>
                      <p>
                        Finding the proper domain name for your website is
                        simple with NextGenIT. We also provide a full range of
                        domain services, including domain registration, domain
                        transfers, domain expiration protection, and domain
                        privacy, all of which are both affordable and
                        hassle-free.
                      </p>
                    </Carousel.Caption>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>

            <Carousel.Item>
              <Container>
                <Row>
                  <Col md={{ span: 1, offset: 1 }}>
                    <Image
                      src={'./webdesign.png'}
                      alt="Hosting"
                      className="bg-white"
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Carousel.Caption>
                      <h3 class="text-warning">
                        Website design &amp; Development
                      </h3>
                      <p>
                        NextGenIT offers a wide range of website design
                        solutions ranging from Do-It-Myself Web Design Services
                        to fully Custom Website Design using latest technology
                        stack. With NextGenIT's website solutions, you can
                        create a professional looking website in a few clicks or
                        have our expert website designers build a fully custom
                        website for you.
                      </p>
                    </Carousel.Caption>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
            <Carousel.Item>
              <Container>
                <Row>
                  <Col md={{ span: 1, offset: 1 }}>
                    <Image
                      src={'./cloudhosting.png'}
                      alt="Hosting"
                      className="bg-white"
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Carousel.Caption>
                      <h3 class="text-warning">Hosting Service</h3>
                      <p>
                        NextGenIT provides economical web hosting solutions to
                        assist professionals and small businesses make the most
                        of their online presence. We also offer SSL
                        Certificates, or Secure Sockets Layer Certificates,
                        which certify your website and convince clients that it
                        is secure.
                      </p>
                    </Carousel.Caption>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
            <Carousel.Item>
              <Container>
                <Row>
                  <Col md={{ span: 1, offset: 1 }}>
                    <Image
                      src={'./emailhosting.png'}
                      alt="Hosting"
                      className="bg-white"
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Carousel.Caption>
                      <h3 class="text-warning">Professional Email</h3>
                      <p>
                        Present a more professional image and reinforce your
                        brand with a business email address for your domain.
                        Solutions include options for individuals, and email and
                        productivity solutions for small businesses.
                      </p>
                    </Carousel.Caption>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
            <Carousel.Item>
              <Container>
                <Row>
                  <Col md={{ span: 1, offset: 1 }}>
                    <Image
                      src={'./ecommerce.png'}
                      alt="Hosting"
                      className="bg-white"
                    />
                  </Col>
                  <Col md={{ span: 6, offset: 3 }}>
                    <Carousel.Caption>
                      <h3 class="text-warning">eCommerce Solutions</h3>
                      <p>
                        NextGenIT offers customised eCommerce websites that can
                        be used to sell both physical and digital products via
                        online.
                      </p>
                    </Carousel.Caption>
                  </Col>
                </Row>
              </Container>
            </Carousel.Item>
          </Carousel>
        </div>
        <h3 className="text-center text-warning">
          Solutions That Work for Your Business
        </h3>

        <Image
          src={'./software1.png'}
          alt="Hosting"
          className="bg-white mt-5"
        />
        <h3 className="text-center text-warning mt-5">Explore our Offering</h3>
        <div>
          <Container class="container-md">
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <h1>Website Design and Development</h1>
                <p>
                  NextGenIT is a prominent website design firm that specializes
                  in creating creative and functional websites. To help you
                  expand your business and reach your goals, our professional
                  web design services capture your brand, boost conversion
                  rates, and optimize revenue. We create and host quality
                  websites for enterprises, organizations, government, and
                  companies, from conception to strategy to design and
                  implementation.
                </p>
              </Col>
              <Col className="mt-2 mb-2">
                <Image
                  src={'./webdesign.png'}
                  alt="webdesign"
                  className="bg-white"
                  width="300"
                />
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <Image
                  src={'./hosting.png'}
                  alt="Hosting"
                  className="bg-white"
                  width="300"
                />
              </Col>
              <Col className="mt-2 mb-2">
                <h1>Hosting Services</h1>
                <p>
                  NextGenIT is Nepal's most trusted web hosting firm, providing
                  the best web hosting services using AWS EC2, Heroku, and
                  cPanel. We work hard to deliver excellent web hosting services
                  that are backed up by our technical specialists around the
                  clock. Web hosting with NextGenIT is as simple as 1-2-3, and
                  support is available 24 hours a day, 7 days a week. We've got
                  you and your dreams covered, whether you're launching your
                  idea or business or just seeking for a reliable hosting
                  service.
                </p>
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <h1>Domain Registration</h1>
                <p>
                  Do you need a domain name for your business or organization in
                  Nepal? NextGenIT is Nepal's top domain registration firm,
                  offering simple online domain registration. With low-cost
                  registration, the NextGenIT Team will assist you in locating
                  the finest domain name for your business and securing it. For
                  the best Domain Service in Nepal, keep us in mind.
                </p>
              </Col>
              <Col className="mt-2 mb-2">
                <Image
                  src={'./domainreg.png'}
                  alt="domain"
                  className="bg-white"
                  width="300"
                />
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <Image
                  src={'./softwaredevelopment.png'}
                  alt="softwaredevelopment"
                  className="bg-white"
                  width="300"
                />
              </Col>
              <Col className="mt-2 mb-2">
                <h1>Custom Software Development</h1>
                <p>
                  NextGenIT presents a unique service package to suit your
                  needs. NextGenIT is a full-cycle software development company
                  specialized in custom web and mobile development. We provide a
                  wide range of services and work with projects of different
                  scales, from MVP to enterprise.
                </p>
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <h1>eCommerce Solutions</h1>
                <p>
                  Digital commerce is defined as the online sale and purchase of
                  products and services. Even if customer demand for online
                  shopping is at an all-time high, the market is fiercely
                  competitive, and firms must strive for more and greater
                  excellence to stay afloat. NextGenIT delivers technical
                  knowledge and a problem-solving approach to your organization,
                  resulting in cost reductions and revenue development.
                </p>
              </Col>
              <Col className="mt-2 mb-2">
                <Image
                  src={'./ecommerceapp.png'}
                  alt="ecommerce"
                  className="bg-white"
                  width="300"
                />
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <Image
                  src={'./marketting.png'}
                  alt="webportal"
                  className="bg-white"
                  width="300"
                />
              </Col>
              <Col className="mt-2 mb-2">
                <h1>Marketting Support</h1>
                <p>
                  NextGenIT also offers expert advice and guidance to industrial
                  organizations on how to develop, implement, measure, and
                  optimize digital marketing campaigns that increase sales.
                  NextGenIT strategists and production managers, backed by
                  engineers, employ a variety of digital tactics and services to
                  connect your business with buyers. Marketting supports
                  include: Search Engine Optimization (SEO), Search Engine
                  Marketing (SEM), Google Analytics consulting, Social Media
                  Marketing and Website design.
                </p>
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <h1>Mobile Application Developments</h1>
                <p>
                  NextGenIT is an established, dependable, and award-winning
                  mobile app development services company that employs top
                  mobile app developer expertise and has worked with a variety
                  of enterprise-level organizations to help them succeed in the
                  mobile sector. Our mobile experiences are known for being both
                  high-performing and feature-rich while also being resilient,
                  scalable, and secure.
                </p>
              </Col>
              <Col className="mt-2 mb-2">
                <Image
                  src={'./mobileapp.png'}
                  alt="domain"
                  className="bg-white"
                  width="300"
                />
              </Col>
            </Row>
            <Row className="mt-5 bg-light">
              <Col className="mt-2 mb-2">
                <Image
                  src={'./desktopapp.png'}
                  alt="domain"
                  className="bg-white"
                  width="300"
                />
              </Col>
              <Col className="mt-2 mb-2">
                <h1>Desktop Application</h1>
                <p>
                  NextGenIT develops desktop applications that are customised to
                  the customer's demands and match business standards. On the
                  Windows, iOS, and Linux platforms, NextGenIT develops desktop
                  applications. Our ability to create stand-alone applications
                  distinguishes us from the competitors. Our products are timely
                  supplied and of industry-acceptable quality.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <h3 className="text-center text-warning mt-5">
          Technologies and Platforms We Work With
        </h3>
        <div class="mt-2">
          <Container>
            <Row>
              <Col>
                <img src="./logos/html.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/css.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/reactjs.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/nodejs.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/javascript.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/aws.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/java.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/python.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/android.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/oracle.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/sqlserver.jpg" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/dotnet.png" alt="Logo" width="50" />
              </Col>
              <Col>
                <img src="./logos/cloudcomputing.png" alt="Logo" width="50" />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
