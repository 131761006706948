import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const EcommerceScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/ecommerce.png" width={400} />
            </Col>
            <Col>
              <p>
                Ecommerce services cover all technology-related needs of an
                ecommerce business. An ecommerce services provider, NextGenIT
                offers a team of savvy business consultants and pro coders to
                help our ecommerce clients with diverse challenges and business
                goals.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceScreen;
