import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const SystemAdminScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/systemadmin.png" width={400} />
            </Col>
            <Col>
              <p>
                NextGenIT offer most reliable system administration services
                including OS maintenance and troubleshooting services and server
                monitoring services in ...
              </p>
              <p>
                System Admin &amp; Infrastructure Support NextGenIT's team of
                experts bring extensive experience to every client project,
                offering 24 x 7 support and coverage for all major operating
                systems, infrastructure components, and the following
                technologies: Windows Linux UNIX Azure AWS Networks (Cisco and
                other) Private Cloud On Prem Architectures &amp; Systems Service
                Desk Requirements Desktop Support When you hire NextGenIT, you
                also get the NextGenIT Service Platform, a suite of systems
                monitoring and problem-mitigation software. Our proprietary,
                tech-enabled tools deliver high availability, performance, and
                added cost-savings.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SystemAdminScreen;
