import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const ItConsultantScreen = () => {
  return (
    <>
      <Link to="/solutions" className="btn btn-light my-3 common-section">
        Go Back
      </Link>
      <div>
        <Container>
          <Row>
            <Col>
              <img src="/itconsultant.png" width={400} />
            </Col>
            <Col>
              <p>
                Our software engineers will finish your digital transformation
                journey through careful planning and effective execution of the
                outlined IT strategy. <br />
                <br /> Use the expertise and deep tech background of the best
                minds at NextGenIT to create a comprehensive IT strategy for a
                digital and technological transformation of your organization
                that goes in line with your business objectives. Our strategic
                IT consulting will help you automate and digitalise operations,
                optimise the software portfolio, and implement the latest
                technologies.
              </p>
              <p>
                From IT strategy consulting and comprehensive technology
                roadmaps to the end-to-end development of scalable solutions,
                NextGenIT delivers a full-cycle software development services
                that adapt seamlessly to your project requirements and business
                needs.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ItConsultantScreen;
