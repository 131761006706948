import React from 'react';
import { Table } from 'react-bootstrap';

const CareerScreen = ({}) => {
  const data = [
    {
      jobid: 'NextGenSD1',
      position: 'Fullstack Developer',
      location: 'Chitwan, Nepal',
      description: 'Knowledge of client side development using React, nodejs',
    },
    {
      jobid: 'NextGenST1',
      position: 'Software Tester',
      location: 'Chitwan, Nepal',
      description: 'Some experience of testing.',
    },
    {
      jobid: 'NextGenFD1',
      position: 'Front-End Developer',
      location: 'Chitwan, Nepal',
      description: 'Knowledge of client side development using React, nodejs',
    },
  ];

  return (
    <>
      <h1 class="text-warning common-section">We&#x27;re Hiring!</h1>
      <p class="hero-paragraph no-margin">
        At NextGenIT, you'll shape talent solutions for how the world works
        today.
      </p>

      <div class="text-warning">Check out our current open opsition</div>
      <br />
      <Table striped bordered hover responsive className="table-sm">
        <thead>
          <tr>
            <th>JOB ID</th>
            <th>POSITION</th>
            <th>LOCATION</th>
            <th>DESCRIPTION</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((career) => (
            <tr>
              <td>{career.jobid}</td>
              <td>{career.position}</td>
              <td>{career.location}</td>
              <td>{career.description}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />
      <br />
      <br />

      <p style={{ color: 'red' }}>
        Please email your CV to{' '}
        <a href="mailto:nextgenit022@gmail.com">
          <span>nextgenit022@gmail.com </span>
        </a>
        if you are interested in applying for a job.
      </p>
    </>
  );
};

export default CareerScreen;
